import React from "react";
import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import PropTypes from "prop-types";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { Restrict, RestrictByApp } from "components/Restricted";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import style from "components/sidebars/sidebar.module.scss";
import Sidebar, { MenuSeparator } from "./Sidebar";

const AssetSidebar = () => {
  useSetSidebar(true);
  const params = useParams();
  const assetID = params.assetID;
  const asset = useSelector(selectAssetByID(parseInt(assetID || "")));

  return (
    <Sidebar>
      {asset?.catalogProduct && (
        <div className={style.sidebarImageHeader}>
          <img src={asset.catalogProduct.imageUrl} className={style.assetImage} alt="" />
          <Header>{asset.catalogProduct.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <MenuItem to={`/apps/tracking/assets/${assetID}/summary`} icon="/images/icons/tool.svg">
        Tool information
      </MenuItem>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuItem to={`/apps/tracking/assets/${assetID}/tracking`} icon="/images/icons/reader.svg">
          Tool tracking
        </MenuItem>
      </Restrict>
      <MenuItem to={`/apps/tracking/assets/${assetID}/calibration`} icon="/images/icons/system_config_1.svg">
        Calibration
      </MenuItem>
      <MenuItem to={`/apps/tracking/assets/${assetID}/history`} icon="/images/icons/update_2.svg">
        Activity history
      </MenuItem>
      <RestrictByApp app={Apps.REGULATORY_COMPLIANCE}>
        <MenuItem to={`/apps/tracking/assets/${assetID}/calibration`} icon="/images/icons/maintenance_1.svg">
          Calibration
        </MenuItem>
      </RestrictByApp>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`/apps/tracking/assets/${assetID}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};

AssetSidebar.defaultProps = {};

AssetSidebar.propTypes = {
  className: PropTypes.string,
};

export default AssetSidebar;
